<template>
  <v-card hover
          @click="$emit('item-click')">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h4 font-weight-bold">
          <v-tooltip right>
            <template #activator="{on}">
              <span v-on="on">
                {{ item.externalReferenceId }}
              </span>
            </template>
            <span>External Reference Id</span>
          </v-tooltip>
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption">
          {{ item.externalReferenceType }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>{{ item.createdTimestamp }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        color="grey darken-3"
                        outlined
                        v-on="on">
                  {{ item.status.value | startCase }}
                </v-chip>
              </template>
              <span>Service Request Status</span>
            </v-tooltip>
          </v-col>
          <v-col class="py-0">
            <v-tooltip left>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getSeverityColor(item.severity)"
                        v-on="on">
                  {{ item.severity | startCase }}
                </v-chip>
              </template>
              <span>Severity</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pt-0">
      <v-row>
        <v-col cols="12"
               sm="3">
          <div class="text-body-1 font-weight-bold">
            <v-tooltip top>
              <template #activator="{on}">
                <v-icon left
                        v-on="on">
                  mdi-map-marker-distance
                </v-icon>
              </template>
              <span>Customer</span>
            </v-tooltip>
            {{ item.state.value }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { SEVERITY_COLORS } from '@/config/constants';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    getSeverityColor(severity) {
      return SEVERITY_COLORS[severity];
    },
  },
};
</script>
