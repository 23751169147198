var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"hover":""},on:{"click":function($event){return _vm.$emit('item-click')}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4 font-weight-bold"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.item.externalReferenceId)+" ")])]}}])},[_c('span',[_vm._v("External Reference Id")])])],1),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.item.externalReferenceType)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.createdTimestamp))])],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":"grey darken-3","outlined":""}},on),[_vm._v(" "+_vm._s(_vm._f("startCase")(_vm.item.status.value))+" ")])]}}])},[_c('span',[_vm._v("Service Request Status")])])],1),_c('v-col',{staticClass:"py-0"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":"","dark":"","tag":"div","color":_vm.getSeverityColor(_vm.item.severity)}},on),[_vm._v(" "+_vm._s(_vm._f("startCase")(_vm.item.severity))+" ")])]}}])},[_c('span',[_vm._v("Severity")])])],1)],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"left":""}},on),[_vm._v(" mdi-map-marker-distance ")])]}}])},[_c('span',[_vm._v("Customer")])]),_vm._v(" "+_vm._s(_vm.item.state.value)+" ")],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }